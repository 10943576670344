import { render, staticRenderFns } from "./TextExpand.vue?vue&type=template&id=3b3cc4b0&scoped=true&functional=true&"
var script = {}
import style0 from "./TextExpand.vue?vue&type=style&index=0&id=3b3cc4b0&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "3b3cc4b0",
  null
  
)

export default component.exports